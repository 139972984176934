
import {DFTLNGCODE, URL as NEWURL} from '../constants';
import {VENDORID} from '../constants';

export default function RegisterFormRequest(formData) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 1117,
         //   "vendorId": VENDORID,
            "vendorId": "19999006",
            "lang": localStorage.getItem('rcml-lang')??DFTLNGCODE,
            "shopUserFirstname": formData["shopUserFirstname"],
            "shopUserSurname": formData["shopUserSurname"],
            "shopUserEmail": formData["shopUserEmail"],
            "shopUserPhone": formData["shopUserPhone"],
            "customerName": formData["customerName"],
            "customerVATNr": formData["customerVATNr"],
            "customerTaxNr": formData["customerTaxNr"],
            "customerAddress": formData["customerAddress"],
            "customerPostCode": formData["customerPostCode"],
            "customerCity": formData["customerCity"],
            "customerCountry": formData["customerCountry"],
            "customerAdrInfo": formData["customerAdrInfo"],
            "shippingAddress": formData["shippingAddress"]??"",
            "shippingPostCode": formData["shippingPostCode"]??"",
            "shippingCity": formData["shippingCity"]??"",
            "shippingCountry": formData["shippingCountry"]??"",
            "shippingAdrInfo": formData["shippingAdrInfo"]??"",
            "requestNote" : formData["requestNote"]??""

        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(NEWURL, requestOptions);
            const json = await response.json();
            console.log(json)
            return json;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}

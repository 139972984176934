
export const CURRENCY =  '€';
export const DFTLNGCODE =  'de';
export const DIGITS = 2;
export const URL = 'https://api.fbsshop.de';
export const IMAGEURL = 'https://akpa.aysales.de';
export const PAYMENTSERVER = 'https://pay.mobiserver.de:4242';
export const PAYMENTSYSTEM = false;
export const VENDORID = '10003018';
export const COMPANYNAME = 'FALKE Bilgi Teknolojileri Ltd. Sti.'
export const VERSION = "Version 2.1.15"
export const BANNER = false;
export const BRANDS = false;
export const FLAG_ITEM_ON_HAND = false;
